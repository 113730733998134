<template>
  <TModal
    title="Currency"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
  >
    <template #actions>
      <TButtonCreate
        :options="{ disabled: loading }"
        variant="outline"
        @click="create"
        v-if="!is_edit"
      />
      <TButtonSave
        variant="outline"
        @click="update"
        v-if="is_edit"
        :options="{ disabled: loading }"
      />
    </template>
    <TInputText
      label="Currency Id"
      class="mb-3"
      @update:value="input.id = $event"
      :value="input.id"
    />
    <TInputText
      label="Name"
      class="mb-3"
      :value="input.name"
      @update:value="input.name = $event"
    />
    <TInputText
      label="Symbol"
      class="mb-3"
      :value="input.symbol"
      @update:value="input.symbol = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    currency: {
      type: Object,
      require: false,
    },
  },
  inputDefault: {
    id: "",
    name: "",
    symbol: "",
  },
  data() {
    return {
      loading: false,
      data: this.getInput(),
    };
  },
  watch: {
    currency() {
      this.data = this.getInput();
    },
  },
  computed: {
    is_edit() {
      if (!this.currency) {
        return false;
      }
      return !!this.currency.id;
    },
    input: {
      get: function () {
        return this.data;
      },
      set: function (newValue) {
        return (this.data = newValue);
      },
    },
  },
  methods: {
    create() {
      this.loading = true;
      this.$store
        .dispatch("accounting.currencies.create", this.input)
        .then(() => {
          this.input = this.inputDefault();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      this.$store
        .dispatch("accounting.currencies.update", {
          id: this.currency.id,
          attributes: this.input,
        })
        .then((data) => {
          if (data.id) this.$store.dispatch("accounting.currencies.fetch");
          this.$emit("update:show", false);
        });
    },
    inputDefault() {
      return { ...this.$options.inputDefault };
    },
    getInput() {
      if (!this.is_edit) {
        return this.inputDefault();
      }
      return { ...this.currency };
    },
  },
};
</script>
