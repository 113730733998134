<template>
  <div>
    <TTableAdvance
      :items="banks"
      :fields="fields"
      store="accounting.banks"
      resource="accounting/card"
      creatable
      noFilter
      @click-create="showModalCreateBank = true"
      title="Bank"
      class="mb-4"
    >
      <template #append-actions>
        <CCol class="pl-1 pr-1 ml-1" col="12">
          <TButtonEdit @click="showModalEditBank = true" />
        </CCol>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
    </TTableAdvance>
    <TTableAdvance
      :items="currencies"
      :fields="currencyFields"
      store="accounting.currencies"
      resource=""
      noFilter
      title="Currency"
      class="mb-4"
      creatable
      @click-create="showModalCreateCurrency = true"
    >
      <template #append-actions>
        <CCol class="pl-1 pr-1 ml-1" col="12">
          <TButtonEdit @click="showModalEditCurrency = true" />
        </CCol>
      </template>
      <template #id="{ item }">
        <td>
          <TMessage :content="item.id" noTranslate />
        </td>
      </template>
      <template #name="{ item }">
        <td>
          <TMessage :content="item.name" noTranslate />
        </td>
      </template>
      <template #symbol="{ item }">
        <td>
          <TMessage :content="item.symbol" noTranslate />
        </td>
      </template>
    </TTableAdvance>
    <BankModal :show.sync="showModalCreateBank" />
    <BankModal :show.sync="showModalEditBank" :bank="bank" />
    <CurrencyModal :show.sync="showModalCreateCurrency" />
    <CurrencyModal :show.sync="showModalEditCurrency" :currency="currency" />
  </div>
</template>

<script>
import BankModal from "./BankModal.vue";
import CurrencyModal from "./CurrencyModal.vue";
import { mapGetters } from "vuex";

export default {
  components: { BankModal, CurrencyModal },
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Bank Id", _style: "width: 300px" },
        { key: "name", label: "Bank" },
      ],
      currencyFields: [
        { key: "_", _style: "width: 40px" },
        { key: "id", label: "Currency Id", _style: "width: 300px" },
        { key: "name", label: "Name" },
        { key: "symbol", label: "Symbol" },
      ],
      showModalCreateBank: false,
      showModalCreateCurrency: false,
      showModalEditBank: false,
      showModalEditCurrency: false,
    };
  },
  computed: {
    ...mapGetters({
      banks: "accounting.banks.list",
      bank: "accounting.banks.detail",
      currencies: "accounting.currencies.list",
      currency: "accounting.currencies.detail",
    }),
  },
};
</script>
